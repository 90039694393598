<template>
  <ion-page>
    <app-header back="home" title="영업속보" />
    <ion-content :fullscreen="true">
      <template v-if="isConnected">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size-md="1" size-xs="2">
              <span>일자</span>
            </ion-col>
            <ion-col size-md="1" size-xs="1" @click="dateBack">
              <ion-icon :icon="arrowBack"></ion-icon>
            </ion-col>
            <ion-col size-md="3" size-xs="8">
              <ion-input class="calendar" v-model="frMonth" id="frMonth" readonly  @click="popChange">
                <!-- <ion-icon :icon="calendar"></ion-icon> -->
              </ion-input>
              <ion-popover trigger="frMonth" @didDismiss="onWillDismissPopover">
                <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM"  v-model="frMonth" presentation="date" :show-default-buttons="true">
                </ion-datetime>
              </ion-popover>
            </ion-col>
            <ion-col size-md="1" size-xs="1" @click="dateForward">
              <ion-icon :icon="arrowForward"></ion-icon>
            </ion-col>
            <ion-col size-md="3" size-xs="6">
              <ion-button expand="block" class="excel" @click="exportToExcel" color="success">엑셀변환</ion-button>
            </ion-col>
            <ion-col size-md="3" size-xs="6">
              <ion-button expand="block" @click="onReConnect" color="medium">조회</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <vue-good-table 
          :columns="columns" 
          :rows="brnewss" 
          :row-style-class="rowStyleFunction"
          :sort-options="{
            enabled: false,
          }"
          max-height="80vh"> 
       </vue-good-table>
      </template>
      <template v-else>
        <network-error v-on:@onReConnect="onReConnect" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonDatetime,
  onIonViewWillEnter,
  IonButton,
  IonIcon,
  IonInput,
  IonPopover,
  IonGrid,
  IonCol,
  IonRow,
  popoverController,
  onIonViewWillLeave
} from '@ionic/vue';
import { defineComponent, reactive, ref, computed } from 'vue';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { VueGoodTable } from 'vue-good-table-next';
import { UseBrnews } from '@/api/services/brnews';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { setDateFormat  } from '@/utils/dateFormatter';
import * as XLSX from 'xlsx';
import { useStore } from 'vuex';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonDatetime,
    VueGoodTable,
    IonButton,
    IonInput,
    IonPopover,
    IonGrid,
    IonCol,
    IonRow,
  },

  setup() {
    const d = new Date();
    const year = d.getFullYear(); // 년
    const month = d.getMonth();   // 월
    const day = d.getDate();      // 일
    const frMonth = ref(setDateFormat(new Date(year, month, day - 1), 'YYYY-MM-DD'));
    const { isConnected, fetchDatas, brnewss } = UseBrnews();

    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();

    onIonViewWillEnter(async () => {

      saveLog(auth.value.id, '영업속보');
    });

    const form = reactive({
      popoverState: false
    });
    async function onWillDismissPopover() {
      form.popoverState = false;
    }
    async function popChange() {
      form.popoverState = true;
    }

    function customd01(row) {
      if (row.day_qunt) return row.day_qunt.toLocaleString('ko-KR', { maximumFractionDigits: 1 });
    }

    function customd02(row) {
      if (row.assign_qunt) return row.assign_qunt.toLocaleString('ko-KR', { maximumFractionDigits: 1 });
    }

    function customd03(row) {
      if (row.plan_month) return row.plan_month.toLocaleString('ko-KR', { maximumFractionDigits: 1 });
    }

    function customd04(row) {
      if (row.sale_month) return row.sale_month.toLocaleString('ko-KR', { maximumFractionDigits: 1 });
    }

    function customdc(row) {
      if (row.day_qunt || row.assign_qunt || row.plan_month || row.sale_month ) return 'currency';
    }

    const columns = reactive([
      {
        label: '구분',
        field: 'office_name',
        width: '100px',
      },
      {
        label: '',
        field: 'tpctname',
        width: '71px',
      },
      {
        label: '',
        field: 'sicdname',
        width: '71px',
      },
      {
        label: '일계',
        field: customd01,
        width: '71px',
        tdClass: customdc,
      },
      {
        label: '예정량',
        field: customd02,
        width: '100px',
        tdClass: customdc,
      },
      {
        label: '계획(월)',
        field: customd03,
        width: '100px',
        tdClass: customdc,
      },
      {
        label: '실적(월)',
        field: customd04,
        width: '100px',
        tdClass: customdc,
      },
      {
        label: '달성율(월)',
        field: 'rate_month',
        width: '100px',
      },
    ]);


    async function onReConnect() {
      const frdutiesPromise = fetchDatas(frMonth.value.substring(0, 10).replaceAll('-', ''));
      await frdutiesPromise;
    }
    
    const exportToExcel = () => {
      const data =  Object.values(brnewss.value).map(row => [row.office_name, row.tpctname, row.sicdname, row.day_qunt, row.assign_qunt, 
      row.plan_month, row.sale_month, row.rate_month,]);
      data.unshift(columns.map(col => col.label));
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "영업속보.xlsx");
    }

    async function searchfrmu() {   // searchGnmu
      await fetchDatas(frMonth.value.substring(0, 10).replaceAll('-', ''));
    }

    function rowStyleFunction(row) {
      if (row.sicdname === '합계' && row.tpctname !== ''){
        return 'row-total-color';
      }
    }

    onIonViewWillLeave(async () => {
      if (form.popoverState == true) {
        await popoverController.dismiss()
      }
    });

    function dateForward() {
      const date = frMonth.value.replaceAll('-', '');
      const year = date.substr(0, 4);
      const month = date.substr(4, 2);
      const day = date.substr(6, 2);
      const dateTo = new Date(Number(year), Number(month) - 1, Number(day)) ;
      frMonth.value = setDateFormat(dateTo.setDate(dateTo.getDate() + 1), 'YYYY-MM-DD'); 
    }

    function dateBack() {
      const date = frMonth.value.replaceAll('-', '');
      const year = date.substr(0, 4);
      const month = date.substr(4, 2);
      const day = date.substr(6, 2);
      const dateTo = new Date(Number(year), Number(month) - 1, Number(day)) ;
      frMonth.value = setDateFormat(dateTo.setDate(dateTo.getDate() - 1), 'YYYY-MM-DD'); 
    }

    return {
      isConnected,
      frMonth,
      columns,
      brnewss,
      searchfrmu,
      onReConnect,
      exportToExcel,
      rowStyleFunction,
      onWillDismissPopover,
      popChange,
      dateForward,
      dateBack,
      arrowBack,
      arrowForward,
    };
  }
});

</script>
<style scoped>
ion-content {
  text-align: center;
}
ion-col {
  padding: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  color: black;
  width: 100%;
}
ion-col .calendar {
    padding: 0;
}
ion-grid {
    padding: 0;
}
.title {
  display: block;
  text-align: center;
  font-size: 1.2em;
  padding: 0.5em;
}
ion-datetime {
  width: 100%;
}  
</style>